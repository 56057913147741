import dayjs from "dayjs";
import { ShipDto, ShipLastLocation, ShipLastLocationDto, ShipType } from "../models/ship.models";
import { RTEventLimitations, RtEvent } from "../view-models/event.view.model";
import { EventSeverity, EventType } from "../widgets/mapbox/models/mapbox-event.models";
import { EventMapEntity, PolygonEntity, ShipMapEntity } from "../widgets/mapbox/models/mapbox.models";
import _ from 'lodash';
import { PolygonAreaBorderColors, PolygonType } from "../models/polygon.model";
import { PolygonAreaColors } from "../models/polygon.model";
import { PolygonDto, RTEventLimitationsDto } from "../models/events.model";
import { mapRtEventLimitationsDtoToRtEventLimitations } from "./events";

export function getMapEntities(
  shipLastLocation: ShipLastLocation,
  shipData: ShipMapEntity,
  rtEvent: RtEvent
): { shipEntity?: ShipMapEntity; eventEntity?: EventMapEntity } {
  if (rtEvent.timestampEnd) {
    return {
      eventEntity: {
        id: rtEvent.eventId,
        shipId: rtEvent.shipId,
        eventType: rtEvent.type as EventType,
        severity: rtEvent.severity as EventSeverity,
        image: `/assets/map/events/${_.snakeCase(rtEvent.type)}/${_.snakeCase(rtEvent.severity)}.png`,
        lat: shipLastLocation.latitude ?? rtEvent.lat,
        long: shipLastLocation.longitude ?? rtEvent.long,
      },
      shipEntity: undefined
    };
  } else {
    return {
      eventEntity: undefined,
      shipEntity: shipData
    }
  }
}

export function calculatePolygonDiameter(polygon: PolygonEntity): number {
  const edges = polygon.coordinates.reduce(({ maxX, maxY, minX, minY }, [highX, highY, lowX, lowY]) => {
    return {
      maxX: Math.max(maxX, highX || 0),
      maxY: Math.max(maxY, highY || 0),
      minX: Math.min(minX, lowX || 0),
      minY: Math.min(minY, lowY || 0),
    };
  }, { maxX: 0, maxY: 0, minX: 0, minY: 0 });
  return Math.sqrt(Math.pow(edges.maxX - edges.minX, 2) + Math.pow(edges.maxY - edges.minY, 2));
}

export function transformShipDtoToShipMapEntity(shipDto: ShipDto): ShipMapEntity {
  let shipType = _.startCase(shipDto.ship_type.replace('RoRo/Vehicle', 'ro_ro_vehicle')).replaceAll(' ', '');
  shipType = ShipType[shipType as keyof typeof ShipType] ? ShipType[shipType as keyof typeof ShipType] : ShipType.ContainerShip;
  return {
    shipId: shipDto.ship_id,
    shipType: shipType,
    shipName: shipDto.ship_name,
    lastConnection: shipDto.last_connection_datetime ? new Date(shipDto.last_connection_datetime) : null,
    sog: shipDto.sog,
    cog: shipDto.cog,
    maxRoll: shipDto.max_roll,
    maxPitch: shipDto.max_pitch,
    safetyScore: shipDto.avg_score ?? 0,
    image: `/assets/map/ships/${_.snakeCase(shipType)}/ship_icon.png`,
    lat: shipDto.latitude ?? 0,
    long: shipDto.longitude ?? 0,
  };
}

export function mapShipLastLocationDtoToShipLastLocation(lastLocation: ShipLastLocationDto): ShipLastLocation {
  const timeString = _.first(lastLocation.time.split('+'));
  return {
    latitude: lastLocation.latitude,
    longitude: lastLocation.longitude,
    sog: Math.floor(lastLocation.sog),
    time: timeString ? dayjs(new Date(timeString)).format('HH:mm') : 'N/A',
  };
}

export function parsePolygonCoordinates(edges: string): number[][] {
  return edges
    ? edges
      .replace('LINESTRING(', '')
      .replace(')', '')
      .split(',')
      .map(pair => pair.split(' ').map(Number))
    : []
}

export function mapPolygonDtoToPolygonEntity(polygonDto: PolygonDto): PolygonEntity {
  const textLabel = formatPolygonAreaLabel(polygonDto);
  return {
    id: polygonDto.polygon_id,
    textLabel: textLabel,
    coordinates: parsePolygonCoordinates(polygonDto.edges),
    labelType: 'red',
    labelSource: polygonDto.polygon_type_name as PolygonType,
    color: PolygonAreaColors[_.snakeCase(polygonDto.polygon_type_name) as keyof typeof PolygonAreaColors],
    borderColor: PolygonAreaBorderColors[_.snakeCase(polygonDto.polygon_type_name) as keyof typeof PolygonAreaBorderColors],
  }
}

export function formatPolygonAreaLabel(polygon: PolygonDto): string {
  let formatedPolygonAreaLabel = polygon.area_of_interest ?? polygon.area_of_interest;
  if (
    polygon.polygon_type_name === 'compliance' &&
    polygon.aoi_compliance_limitations
  ) {
    const formattedLimitations = `Limitation: ${formatRTEventLimitations(
      polygon.aoi_compliance_limitations
    )}`;

    formatedPolygonAreaLabel = `${formatedPolygonAreaLabel}\n${formattedLimitations}`;
  }
  return formatedPolygonAreaLabel;
}


export function formatRTEventLimitations(
  rtEventLimitationsDto: RTEventLimitationsDto
): string {
  const rtEventLimitationsDescriptions: string[] = [];
  const rtEventLimitations = mapRtEventLimitationsDtoToRtEventLimitations(rtEventLimitationsDto) as RTEventLimitations;

  if (rtEventLimitations.sog?.maxAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Max Allowed SOG: ${rtEventLimitations.sog!.maxAllowed} Kts`
    );
  }
  if (rtEventLimitations.sog?.minAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Min Allowed SOG: ${rtEventLimitations.sog!.minAllowed} Kts`
    );
  }
  if (rtEventLimitations.sog?.notInRange !== undefined) {
    rtEventLimitationsDescriptions.push(
      `SOG Is Not Within: ${rtEventLimitations.sog!.notInRange.min}-${rtEventLimitations.sog!.notInRange.max} Kts`
    );
  }
  if (rtEventLimitations.cog?.maxAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Max Allowed COG: ${rtEventLimitations.cog!.maxAllowed}°`
    );
  }
  if (rtEventLimitations.cog?.minAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Min Allowed COG: ${rtEventLimitations.cog!.minAllowed}°`
    );
  }
  if (rtEventLimitations.cog?.notInRange !== undefined) {
    rtEventLimitationsDescriptions.push(
      `COG Is Not Within: ${rtEventLimitations.cog!.notInRange.min}°-${rtEventLimitations.cog!.notInRange.max}°`
    );
  }
  return rtEventLimitationsDescriptions.join(', ');
}