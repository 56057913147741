import { AppType } from '../types/app-types';
import { EnvironmentType } from '../types/environment';
import { Environment } from './environment-interface';

export const environment: Environment = {
  type: EnvironmentType.Development,
  appType: AppType.Mobile,
  launchdarkly: '65f2c617b133e2109df3e82c',
  mobileAppOrigin: 'https://orcaai-dashboard-dev.mobile.orca-technical.com',
  desktopAppOrigin: 'https://orcaai-dashboard-dev.orca-technical.com',
  awsFunctionsRegion: 'europe-west3',
  api: {
    ships: 'https://dev-fsd-fleets-api.orca-ai.io/ships',
    polygonByFleetId:
      'https://dev-fsd-fleets-api.orca-ai.io/ships/geo-polygons/fleet/',
    rtEvent: 'https://dev-fsd-event-api.orca-ai.io/rt-events',
    liveStream: 'https://fns5zluk05.execute-api.us-east-1.amazonaws.com',
    liveStreamQuality:
      'https://1s2pzowvu3.execute-api.us-east-1.amazonaws.com/dev/',
    liveStreamConnectivity:
      'https://fns5zluk05.execute-api.us-east-1.amazonaws.com/dev',
    shipsGallery: 'https://dev-fsd-fleets-api.orca-ai.io/ships/gallery',
  },
  firebase: {
    apiKey: 'AIzaSyCA62hea7EUxCjMI3nBDAVvFGOAWZPIFlM',
    authDomain: 'orca-ai-dashboard.firebaseapp.com',
    projectId: 'orca-ai-dashboard',
    storageBucket: 'orca-ai-dashboard.appspot.com',
    messagingSenderId: '648528894074',
    appId: '1:648528894074:web:ba741c3551eaa9dcfa03c3',
    measurementId: 'G-FM13K3KWQ4',
  },
  mapbox: {
    mapProviderAccessToken:
      'pk.eyJ1IjoiZWxhZHYiLCJhIjoiY2w3NjNjc2VlMDI1YjNwcGJjZ3pkYmZpNSJ9.moCMLFSo_aRLzGRdHnauCw',
  },
  meteoblue: {
    tilesUrl: 'https://maps-api.meteoblue.com/v1/map',
    timeUrl: 'https://maps-api.meteoblue.com/v1/time/daily',
    format: 'vector',
    domain: 'ICONAUTO',
    apiKey: 'be72f76237db',
  },
  mixpanel: {
    enabled: true,
    proxyUrl: 'https://fleetview-user-events-staging.orca-technical.com',
    apikey: 'c6d317d80c04201d1218dbd2e3f62acd',
  },
};
