export enum PolygonAreaColors {
  'compliance' = '#9c9c9c',
  'no_go_zone' = '#e73252',
}

export enum PolygonAreaBorderColors {
  'compliance' = '#9c9c9c4d',
  'no_go_zone' = '#e7325233',
}

export const PolygonTypes = {
  compliance: 'compliance',
  noGoZone: 'no_go_zone',
} as const;

export type PolygonType = typeof PolygonTypes[keyof typeof PolygonTypes];
