export enum ShipType {
  BulkCarrier = 'bulk_carrier',
  ContainerShip = 'container_ship',
  CrudeOilTanker = 'crude_oil_tanker',
  LngTanker = 'lng_tanker',
  LpgTanker = 'lpg_tanker',
  Tug = 'tug',
  RoRoVehicleCarrier = 'roro_vehicle_carrier',
}

export interface ShipLastLocation {
  latitude: number;
  longitude: number;
  sog: number;
  time: string;
}

export interface ShipLastLocationsDto {
  locations: ShipLastLocationDto[];
  ship_name: string;
  ship_id: number;
  ship_type: string;
}

export interface ShipLastLocationDto {
  longitude: number;
  latitude: number;
  sog: number;
  time: string;
}

export interface ShipDto {
  readonly ship_id: number;
  readonly fleet_id: number;
  readonly ship_name: string;
  readonly host_name: string;
  readonly fleet_name: string;
  readonly last_connection_datetime: string | null;
  readonly latest_event_added_date: string | null;
  readonly textual_location: string | null;
  readonly last_location_timestamp: string | null;
  readonly sog: number | null;
  readonly cog: number | null;
  readonly max_pitch: number | null;
  readonly max_roll: number | null;
  readonly latest_event_added_id: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly avg_score: number | null;
  readonly score_trend: number | null;
  readonly ship_type: ShipType;
  readonly total_last_sailed_distance: number;
}