import { Injectable } from '@angular/core';
import {
  PolygonEntity,
  ShipMapEntity,
} from '../widgets/mapbox/models/mapbox.models';
import {
  ShipDto,
  ShipLastLocation,
  ShipLastLocationsDto,
} from '../models/ship.models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, firstValueFrom, forkJoin, map, Observable, of } from 'rxjs';
import _ from 'lodash';
import dayjs from 'dayjs';
import { RtEvent } from '../view-models/event.view.model';
import {
  diameterToZoom,
  GeoRegionMileDiameters,
} from '../models/geo-locations.model';
import { EasingOptions } from 'mapbox-gl';
import { MapData } from '../models/map.model';
import {
  getMapEntities,
  transformShipDtoToShipMapEntity,
  mapShipLastLocationDtoToShipLastLocation,
  mapPolygonDtoToPolygonEntity,
  calculatePolygonDiameter,
} from '../utils/transform-map-dtos';
import { PolygonDto } from '../models/events.model';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  constructor(private httpClient: HttpClient) {}

  getGeoPolygonEntity(fleetId: number, polygonId?: string): Observable<PolygonEntity | undefined> {
    if (!polygonId) {
      return of(undefined);
    }
      return this.httpClient.get<PolygonDto>(`${environment.api.polygonByFleetId}${fleetId}/polygon/${polygonId}`).pipe(
      map((polygonDto) =>
        mapPolygonDtoToPolygonEntity(polygonDto)
      ),
      catchError((error: HttpErrorResponse) => {
        return of(undefined);
      })
    );
  }

  getShipLastLocations(
    shipId: number,
    startDate: string,
    endDate: string
  ): Observable<ShipLastLocation[]> {
    return this.httpClient
      .get<ShipLastLocationsDto>(
        `${environment.api.ships}/${shipId}/last_locations`,
        {
          params: {
            end_date: endDate,
            start_date: startDate,
          },
        }
      )
      .pipe(
        map((shipLastLocations): ShipLastLocation[] =>
          shipLastLocations.locations.map(
            mapShipLastLocationDtoToShipLastLocation
          )
        ),
        catchError((error: HttpErrorResponse) => {
          return of([]);
        })
      );
  }

  getShipData(
    startDate: string,
    endDate: string,
    shipId: number
  ): Observable<ShipMapEntity | undefined> {
    return this.httpClient
      .get<ShipDto[]>(`${environment.api.ships}/management/${shipId}`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      .pipe(
        map((shipDtos) => _.first(shipDtos)),
        map(
          (shipDto): ShipMapEntity | undefined =>
            shipDto && transformShipDtoToShipMapEntity(shipDto)
        ),
        catchError((error: HttpErrorResponse) => {
          return of(undefined);
        })
      );
  }

  async getRTMapData(rtEvent: RtEvent): Promise<MapData> {
    const endDate = rtEvent.timestampEnd ?? new Date();
    const startDate = dayjs(endDate).subtract(1, 'day').toDate();
    const endDateString = endDate.toISOString();
    const startDateString = startDate.toISOString();
    const { polygonEntity, locations, shipData } = await firstValueFrom(
      forkJoin({
        polygonEntity: this.getGeoPolygonEntity(
          rtEvent.fleetId,
          rtEvent.polygonId
        ),
        locations: this.getShipLastLocations(
          rtEvent.shipId,
          startDateString,
          endDateString
        ),
        shipData: this.getShipData(
          startDateString,
          endDateString,
          rtEvent.shipId
        ),
      })
    );

    const eventAreaDiameter = polygonEntity
      ? calculatePolygonDiameter(polygonEntity)
      : GeoRegionMileDiameters[
          rtEvent.location as keyof typeof GeoRegionMileDiameters
        ];
    const flyTo: EasingOptions = {
      center: { lat: rtEvent.lat, lng: rtEvent.long },
      zoom: diameterToZoom(eventAreaDiameter),
    };
    if (!shipData) {
      return {
        polygonEntity,
        shipLastLocations: locations,
        shipEntity: undefined,
        eventEntity: undefined,
        flyTo,
      };
    } else {
      const { shipEntity, eventEntity } = getMapEntities(
        locations[locations.length - 1],
        shipData,
        rtEvent
      );
      return {
        polygonEntity,
        shipLastLocations: locations,
        shipEntity,
        eventEntity,
        flyTo,
      };
    }
  }
}
